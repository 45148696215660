// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banner-js": () => import("./../src/pages/banner.js" /* webpackChunkName: "component---src-pages-banner-js" */),
  "component---src-pages-catering-index-js": () => import("./../src/pages/catering/index.js" /* webpackChunkName: "component---src-pages-catering-index-js" */),
  "component---src-pages-catering-niederoesterreich-js": () => import("./../src/pages/catering/niederoesterreich.js" /* webpackChunkName: "component---src-pages-catering-niederoesterreich-js" */),
  "component---src-pages-catering-wien-js": () => import("./../src/pages/catering/wien.js" /* webpackChunkName: "component---src-pages-catering-wien-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-image-js": () => import("./../src/pages/image.js" /* webpackChunkName: "component---src-pages-image-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-speisekarte-js": () => import("./../src/pages/speisekarte.js" /* webpackChunkName: "component---src-pages-speisekarte-js" */),
  "component---src-pages-warten-js": () => import("./../src/pages/warten.js" /* webpackChunkName: "component---src-pages-warten-js" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"helmarts-food-and-drinks","short_name":"helmarts","start_url":"/","background_color":"#EC2E21","theme_color":"#EC2E21","display":"minimal-ui","icon":"src/images/helmarts-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164386512-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/datenschutz/"],"pageTransitionDelay":100,"siteSpeedSampleRate":100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
